import { useConfig } from '../';

const LogRocket = require('logrocket/setup')({
  sdkServer: 'https://cdn.lr-hv-in.com',
  ingestServer: 'https://r.lr-hv-in.com',
});

function isJson(value: any) {
  try {
    return !!value && JSON.parse(value);
  } catch (e) {
    return false;
  }
}

const blockedLRRoutes = [
  'capi/accounts',
  'invest-accounts/users',
  'users/wealth-accounts',
  'networth-details',
  'Phoenix/getUserAccountDetails',
  'customers/authenticate/api',
];

/* Hook for handling logrocket */
const useLogRocket = () => {
  const { logRocketId } = useConfig();

  const initializeLogRocket = () => {
    LogRocket.init(logRocketId, {
      shouldCaptureIP: false,
      rootHostname: 'ally.com',
      network: {
        requestSanitizer: (request: any) => {
          if (request.headers.Authorization) {
            request.headers.Authorization = 'XXXXXX';
          }
          return request;
        },
        responseSanitizer: (response: any) => {
          const res = response.url.toLowerCase();
          // Remove any query params in the blocked list and replace their value with x'
          const body = isJson(response.body);
          if (blockedLRRoutes.some((route) => res.includes(route))) {
            delete response.body;
          } else if (body) {
            if (body.access_token) {
              body.access_token = 'XXXXXX';
            }
            if (body.accountNumber) {
              body.accountNumber = 'YYYYYY';
            }
            response.body = JSON.stringify(body);
          }

          return response;
        },
      },
    });
  };
  return {
    initializeLogRocket,
    LogRocket,
  };
};

export default useLogRocket;
